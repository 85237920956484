<template>
  <div>
    <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
      <template v-slot:activator="{ on }">
        <v-chip pill small v-on="on">
          <v-avatar left :color="project.color" dark>
            <span class="white--text">{{ project.name | initials }}</span>
          </v-avatar>
          {{ project.name }}
        </v-chip>
      </template>
      <v-card outlined width="300px">
        <v-list dark>
          <v-list-item>
            <v-list-item-avatar :color="project.color">
              <span class="white--text">{{ project.name | initials }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ project.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ project.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="menu = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Annual Employee Cost</v-list-item-title>
              <v-list-item-subtitle>{{ project.annual_employee_cost | toUSD }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Business Year Hours</v-list-item-title>
              <v-list-item-subtitle>{{ project.business_year_hours }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Owner Email</v-list-item-title>
              <v-list-item-subtitle>{{ project.owner_email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-office-building</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Organization ID</v-list-item-title>
              <v-list-item-subtitle>{{ project.organization_id }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ProjectChipWithCard",
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
}
</script>
