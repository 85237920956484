<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <ValidationObserver v-slot="{ invalid, validated }">
      <v-navigation-drawer v-model="showCreateEdit" app clipped right width="700">
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
              <v-list-item-title v-else class="title"> New </v-list-item-title>
              <v-list-item-subtitle>Weblink</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              icon
              color="info"
              :loading="loading"
              :disabled="invalid || !validated"
              @click="save()"
            >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="secondary" @click="closeCreateEdit()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item>
        </template>

        <v-card flat>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <ValidationProvider name="Description" rules="required" immediate>
                    <v-text-field
                    v-model="description"
                    slot-scope="{ errors, valid }"
                    :error-messages="errors"
                    :success="valid"
                    label="Description"
                    clearable
                    auto-grow
                    required
                    />
                  </ValidationProvider>
                </v-flex>
                <v-flex xs12>
                  <ValidationProvider name="Title" immediate>
                    <v-text-field
                      v-model="title"
                      slot-scope="{ errors, valid }"
                      :error-messages="errors"
                      :success="valid"
                      label="Title"
                      clearable
                      auto-grow
                    />
                  </ValidationProvider>
                </v-flex>
                <v-flex xs12>
                  <ValidationProvider name="URL" rules="required" immediate>
                    <v-text-field
                      v-model="url"
                      slot-scope="{ errors, valid }"
                      :error-messages="errors"
                      :success="valid"
                      label="URL"
                      required
                    />
                  </ValidationProvider>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>

      </v-navigation-drawer>
    </ValidationObserver>
  </template>


  <script>
  import { mapFields } from "vuex-map-fields"
  import { mapActions } from "vuex"
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
  import { required } from "vee-validate/dist/rules"

  extend("required", {
    ...required,
    message: "This field is required",
  })

  export default {
    name: "WebLinkNewEditSheet",

    components: {
      ValidationObserver,
      ValidationProvider,
    },
    computed: {
      ...mapFields("weblink", [
        "selected.description",
        "selected.title",
        "selected.url",
        "selected.id",
        "selected.project",
        "selected.loading",
        "dialogs.showCreateEdit",
      ]),
      ...mapFields("route", ["query"]),
    },

    methods: {
      ...mapActions("weblink", ["save", "closeCreateEdit"]),
    },

    created() {
      if (this.query.project) {
        this.project = { name: this.query.project }
      }
    },
  }
  </script>
