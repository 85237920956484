<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn small color="info" class="mr-2" @click="createEditShow()">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          New Observability Link
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="4" v-for="item in items" :key="item.id">
              <v-card class="pa-3 mb-3">
                <v-card-title>
                  <div class="d-flex justify-space-between align-center w-100">
                    <a :href="item.url" target="_blank" rel="noopener noreferrer">
                      {{ item.url }}</a>
                    <div>
                    </div>
                  </div>
                </v-card-title>
                <v-card-subtitle>
                  <div>
                    <v-icon small left>mdi-label-outline</v-icon>
                    Title: {{ item.title }}
                  </div>
                  <div>
                    <v-icon small left>mdi-axis-arrow-info</v-icon>
                    Description: {{ item.description }}
                  </div>
                  <div>
                    <v-icon small left>mdi-web</v-icon>
                    Created: {{ item.type }}
                  </div>
                </v-card-subtitle>
                <v-card-actions>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-if="item.count > 10"
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="mb-2 ml-2"
                        >
                          <v-icon left x-small>mdi-domain</v-icon>
                          {{ item.count }}
                        </v-chip>
                        <v-chip
                          v-else
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="mb-2 ml-2"
                        >
                          <v-icon left x-small>mdi-domain</v-icon>
                          {{ item.count }}
                        </v-chip>
                      </template>
                      <span>Number of times this link has been shared in incidents</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="createEditShow(item)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="removeShow(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  <v-btn small icon @click="copyToClipboard(item.url)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import RouterUtils from "@/router/utils"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/data/weblink/DeleteDialog.vue"
import NewEditSheet from "@/data/weblink/NewEditSheet.vue"
import ProjectCard from '@/project/ProjectCard.vue'

export default {
  name: "WeblinkTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    ProjectCard,
  },
  data() {
    return {
      headers: [],
    }
  },

  computed: {
    ...mapFields("weblink", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),

    defaultUserProjects: {
    get() {
      let d = null
      if (this.projects) {
        let d = this.projects.filter((v) => v.default === true)
        return d.map((v) => v.project)
      }
      return d
    },
  },
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    if (this.query.project) {
      this.project = [{ name: this.query.project }]
    }

    this.getAll()
    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("weblink", ["getAll", "createEditShow", "removeShow", "copyToClipboardSuccess"]),
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copyToClipboardSuccess()
      })
    },
  },
}
</script>
